<template>
  <div>
    <b-row>
      <b-col lg="6" offset-lg="3">
        <!-- <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSfQyANCrCjDqvAuebZiN1DK2PT6yX9BYL35eQGLfhgRn2RhyQ/viewform?embedded=true"
          width="640"
          height="1894"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          >Cargando…
        </iframe> -->

        <!-- <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeP7ZuBIjVBgIHLMN-ddOoa0LooQup_ZJVsczkrenScmxO2iQ/viewform?embedded=true"
          width="640"
          height="1894"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          >Cargando…
        </iframe> -->
        <!-- walker form to Pasto -->
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSfu7LlLKXJIFUyagHHjAkwu3nqt_4ka0Wn61ZyZtlGcIBiuIw/viewform?embedded=true"
          width="600"
          height="1942"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          >Cargando…</iframe
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name:"walkerform",
};

</script>

<style lang="scss" scoped></style>
